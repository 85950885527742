export default () => ({
  allProducts: [
    {
      title: "Best sellers",
      items: [
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men College School Bag Laptop Backpack For Men 17.3''Large-capacity Backpack USB Port Bag Business Backpack Oxford Waterproof",
          price_diff: 200,
          price: 220,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6537/6537363_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Thermaltake S100 Tempered Glass Snow Edition Micro-ATX mini-Tower Computer Case with 120mm Rear Fan Pre-Installed CA-1Q9-00S6WN-00, White",
          price_diff: 200,
          price: 600,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6541/6541285_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 10,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "Reditor67",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Broadlink RM4 pro Smart IR/RF Remote Control Hub with Sensor Cable-WiFi IR/RF Blaster for Smart Home Automation, TV, Curtain, Shades Remote, Works with Alexa...",
          price_diff: 330,
          price: 330,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6557/6557718_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 50,
          collateral: 15,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "Hosky67",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men's New 100% Cotton American Hoodies Autumn Winter Vintage Long Sleeve Pullover Hard Rock Band Pink Sweater Floyds Hoodie",
          price_diff: 200,
          price: 150,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6565/6565842_sd.jpg",
          payment_type: "2 Slots",
          stock_supply: 5,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "JenniferPro",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Don't Believe Everything You Think by Joseph Nguyen Why Your Thinking Is The Beginning & End Of Suffering Paperback English Book",
          price_diff: 200,
          price: 150,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6562/6562576_rd.jpg",
          payment_type: "2 Slots",
          stock_supply: 5,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "JenniferPro",
        },

        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men Outdoor Hiking Jackets Waterproof Hooded Windbreaker Coat Men Casual Camping Jacket Tactics Military Jackets Men",
          price_diff: 200,
          price: 125,
          image:
            " https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6562/6562576_sd.jpg",
          payment_type: "3 Slots",
          stock_supply: 5,
          collateral: 17,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "CharlesVitalik",
        },

        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men's Sneakers Casual Sports Shoes for Men Lightweight PU Leather Breathable Shoe Mens Flat White Tenis Shoes",
          price_diff: 200,
          price: 200,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6569/6569024_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Silicone Protective Case Cover for AirPods Pro 3 Storage Box Bluetooth Earphone Sleeve Accessories",
          price_diff: 200,
          price: 140,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6469/6469978_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 15,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Cotton Oxford Shirt For Mens Long Sleeve Plaid Striped Casual Shirts Male Pocket Regular-Fit Button-Down Work Man Shirt",
          price_diff: 200,
          price: 170,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6494/6494238_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Face Towel Absorbent Pure Hand Face Cleaning Hair Shower Microfiber Towels Bathroom Home Hotel for Adults",
          price_diff: 200,
          price: 100,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6428/6428324_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
      ],
    },
    {
      title: "Explore",
      items: [
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men College School Bag Laptop Backpack For Men 17.3''Large-capacity Backpack USB Port Bag Business Backpack Oxford Waterproof",
          price_diff: 200,
          price: 220,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6537/6537363_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Thermaltake S100 Tempered Glass Snow Edition Micro-ATX mini-Tower Computer Case with 120mm Rear Fan Pre-Installed CA-1Q9-00S6WN-00, White",
          price_diff: 200,
          price: 600,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6541/6541285_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 10,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "Reditor67",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Broadlink RM4 pro Smart IR/RF Remote Control Hub with Sensor Cable-WiFi IR/RF Blaster for Smart Home Automation, TV, Curtain, Shades Remote, Works with Alexa...",
          price_diff: 330,
          price: 330,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/4901/4901809_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 50,
          collateral: 15,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "Hosky67",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men's New 100% Cotton American Hoodies Autumn Winter Vintage Long Sleeve Pullover Hard Rock Band Pink Sweater Floyds Hoodie",
          price_diff: 200,
          price: 150,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6565/6565842_sd.jpg",
          payment_type: "2 Slots",
          stock_supply: 5,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "JenniferPro",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Don't Believe Everything You Think by Joseph Nguyen Why Your Thinking Is The Beginning & End Of Suffering Paperback English Book",
          price_diff: 200,
          price: 150,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6562/6562576_sd.jpg",
          payment_type: "2 Slots",
          stock_supply: 5,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "JenniferPro",
        },

        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men Outdoor Hiking Jackets Waterproof Hooded Windbreaker Coat Men Casual Camping Jacket Tactics Military Jackets Men",
          price_diff: 200,
          price: 125,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6562/6562576_rd.jpg",
          payment_type: "3 Slots",
          stock_supply: 5,
          collateral: 17,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "CharlesVitalik",
        },

        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Men's Sneakers Casual Sports Shoes for Men Lightweight PU Leather Breathable Shoe Mens Flat White Tenis Shoes",
          price_diff: 200,
          price: 200,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6569/6569024_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 30,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Silicone Protective Case Cover for AirPods Pro 3 Storage Box Bluetooth Earphone Sleeve Accessories",
          price_diff: 200,
          price: 140,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6469/6469978_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 15,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Cotton Oxford Shirt For Mens Long Sleeve Plaid Striped Casual Shirts Male Pocket Regular-Fit Button-Down Work Man Shirt",
          price_diff: 200,
          price: 170,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6494/6494238_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
        {
          id: "P3EWGDGOYGCNCLLT",
          name: "Face Towel Absorbent Pure Hand Face Cleaning Hair Shower Microfiber Towels Bathroom Home Hotel for Adults",
          price_diff: 200,
          price: 100,
          image:
            "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6428/6428324_sd.jpg",
          payment_type: "10 Slots",
          stock_supply: 20,
          collateral: 20,
          rating_count: 4,
          review_count: 883,
          shipping_tax: false,
          shipping_label: "Free shipping",
          shipping_icon: "pi user",
          discount_color: "var(--text-a)",
          discount_label: "TheRock77",
        },
      ],
    },
  ],
});
