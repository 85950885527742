<template>
  <main>
    <router-view />
  </main>
</template>

<script>

export default {
  name: "App",
};

</script>

<style src="./style/global.css"/>


