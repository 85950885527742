
const commit__getAllProducts = (state, data) => {
  state.allProducts = data;
};



export {
  commit__getAllProducts
};
